import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { BodySmall, TitleSecondary } from '@components/styled/Typography';
import { ReactComponent as XMarkIcon } from '@icons/wolfkit-solid/xmark-with-cross-circle-solid.svg';
import DeleteAccountSidebar from '../../../sidebars/DeleteAccount';
const DeleteIcon = styled(XMarkIcon)(props => ({
    width: `${props.theme.spacing_sizes.xs * 6}px`,
    height: `${props.theme.spacing_sizes.xs * 6}px`,
    minWidth: `${props.theme.spacing_sizes.xs * 6}px`,
    circle: {
        fill: `${props.theme.palette.error.light}`,
    },
    path: {
        stroke: `${props.theme.palette.error.main}`,
    },
}));
const DeleteAccountContainer = styled.div(props => ({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'start',
    padding: `${props.theme.spacing_sizes.xm}px ${props.theme.spacing_sizes.l}px`,
    gap: `${props.theme.spacing_sizes.xs * 2}px`,
    backgroundColor: `${props.theme.customColors.container.errorLight}`,
    borderRadius: `${props.theme.spacing_sizes.xs * 1.25}px`,
    cursor: 'pointer',
    flex: 1,
    '&:hover': {
        color: '#fff',
        backgroundColor: props.theme.palette.error.main,
        [`${DeleteIcon}`]: {
            circle: {
                fill: '#FFF',
            },
        },
    },
}));
const AvatarContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `${props.theme.spacing_sizes.xs * 6}px`,
    height: `${props.theme.spacing_sizes.xs * 6}px`,
    minWidth: `${props.theme.spacing_sizes.xs * 6}px`,
    borderRadius: '50%',
    backgroundColor: `${props.theme.customColors.badge.trader.surface}`,
}));
const DeleteAccountTextContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
const Title = styled(TitleSecondary)(props => ({
    lineHeight: `${props.theme.spacing_sizes.xs * 2.5}px`,
    marginBottom: `${props.theme.spacing_sizes.s}px`,
}));
const Description = styled(BodySmall)(props => ({
    fontSize: `${props.theme.spacing_sizes.xs * 1.75}px`,
}));
const DeleteAccount = () => {
    const { t } = useTranslation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarOnClose = () => {
        setIsSidebarOpen(false);
    };
    const openSidebar = () => {
        setIsSidebarOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(DeleteAccountContainer, { onClick: openSidebar, children: [_jsx(AvatarContainer, { children: _jsx(DeleteIcon, {}) }), _jsxs(DeleteAccountTextContainer, { children: [_jsx(Title, { children: t('settings.tabs.personal_info.delete_account.title') }), _jsx(Description, { children: t('settings.tabs.personal_info.delete_account.text') })] })] }), _jsx(DeleteAccountSidebar, { isOpen: isSidebarOpen, onClose: sidebarOnClose, shouldCloseOnOutsideClick: true, destroyOnHide: true })] }));
};
export default DeleteAccount;
